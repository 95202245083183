import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { record } from 'rrweb';
import { apiSendSessionEventsToServer } from 'src/utils/journeyApi';

type StopFunction = () => void;

type SendEventsToServer = (
  session_id: string,
  journey_uuid: string,
  alias_uuid: string,
  eventsBatch: any[],
  duration: number
) => void;

export const useEventRecorder = (sessionUuid: string, journey: any, uuid: string) => {
  const [recorderInitialized, setRecorderInitialized] = useState<boolean>(false);

  const sendEventsToServer: SendEventsToServer = (session_uuid, journey_uuid, alias_uuid, eventsBatch, duration) => {
    const params = {
      events: eventsBatch,
      sessionUuid: session_uuid,
      duration: duration,
      journeyUuid: journey_uuid,
      aliasUuid: alias_uuid,
    };
   
    apiSendSessionEventsToServer(params);

  };

  useEffect(() => {

    if (!journey || !journey.uuid || !sessionUuid) {
        return;
      }

    let events: any[] = [];
    const session_id = sessionUuid;
    const startTime = Date.now();

    // Start recording
    const stopFn: StopFunction = record({
      emit(event) {

        events.push(event);

        if (events.length >= 50) {
          const endTime = Date.now();
          const duration = Math.floor((endTime - startTime) / 1000);
          sendEventsToServer(session_id, journey.uuid, uuid, events.splice(0, events.length), duration);
        }
      },
    }) || (() => {});

    setRecorderInitialized(true);

    return () => {
      stopFn();
    };
  }, [journey, uuid, sessionUuid, recorderInitialized]);

  return { recorderInitialized };
};