import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import delay from 'lodash/delay';
import queryString from 'query-string';

import { useEventRecorder } from 'src/dashboard/rrweb/use-event-recorder';
import { useJourney } from './use-journey.hook';
import { useDocumentVisibilityChange } from 'src/utils/document/visibility-change.hook';
import { useTimer } from 'src/utils/timer.hook';
import { useEmbedVideoTimeMap } from './video-time-map.hook';
import { useEmbedJourneyFlags } from './journey-flags.hook';

import JourneyInactive from 'src/player/JourneyInactive';
import { EmbedPlayerEmailGating } from './player-email-gating';
import { embedUserEventsFactory } from './user-events.factory';
import VideoUpNext from 'src/VideoUpNext';
import VideoPlayer from 'src/VideoPlayer';

import { EmbedPlayerPropsInterface } from './player-props.interface';
import { isEmbeddableVideo, isPlaceholderNode, isJourneyUpsellNode, getNodeByStepPath } from 'src/utils/journey';
import { getJourneyTracker, getJourneySession, getSessionReplayId } from 'src/utils/tracker';
import { getBrandingColor } from 'src/utils/branding';
import { Nullable } from 'src/types/nullable.type';
import {
  getAliasGateViewedForJourney,
  getEmailWithAccessForJourney,
  setAliasGateViewedForJourney,
  storeAccessedEmailForJourney,
} from 'src/utils/journeyAccess';
import { getInitialNode } from 'src/utils/journey';
import { apiSignNda, apiUnlockJourneyByToken } from 'src/utils/journeyApi';
import usePdfTimeMetricsReporter from 'src/utils/pdf-time-metrics-reporter.hook';
import objectPath from 'object-path';
import { listenIframeEvents } from './listen-iframe-events';
import { FeatureFlagsContextProvider } from 'src/common/feature-flags/provider';
import { PlayerBedrockComponent } from 'src/player/bedrock-component';
import { getBrandingColors } from 'src/brand-generation/colors';
import { useApplyBrandColors } from 'src/branding/set-brand-colors';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import JourneyViewNode from 'src/common/JourneyViewNode';
import { useChatStore } from 'src/common/chat/store';
import { useCurrentUser } from 'src/store/user';
import { functionNoop } from 'src/utils/function/noop';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { StepEmailGating } from 'src/player/StepEmailGating';
import { AliasCustomFieldValueType } from 'src/common/interfaces/journey/alias.interface';
import { trackSegmentEvent } from 'src/utils/segment';
import LogAnalytics from 'src/editor/content-creation/analytics/TinyBirdLog';
import { DynamicMeta } from 'src/common/components/dynamic-meta';

const NODE_COMPLETION_THRESHOLD = 75;

export const EmbedPlayer = ({
  uuid,
  trackingUUID,
  startNodeId = null,
  stepPath = null,
  onStepPathUpdate,
  updateStepPathUrl = true,
  previewMode,
  galleryMode,
  aliasSlug,
  commentIdToScroll,
  suggestedCommenterUserUUID,
  disableIndexing,
  token,
}: EmbedPlayerPropsInterface) => {
  const history = useHistory();
  const currentUser = useCurrentUser((state) => state.currentUser);
  const [currentNode, setCurrentNode] = useState<any>();
  const [showNextScreen, setShowNextScreen] = useState<boolean>(true);
  const [passwordIsVerified, setPasswordIsVerified] = useState<boolean>(false);
  const isNextScreenCancelledByChatRef = useRef(false);
  const [analyticsTriggered, setAnalyticsTriggered] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<Nullable<string>>(getEmailWithAccessForJourney(uuid) || null);
  const { isMobileLayout } = useDeviceLayout();
  const { setJourneySlug, setQueryParams, isChannelChatReady } = useChatStore();

  const { setSuccessNotification } = useNotificationMessage();

  const { allNodes, journey } = useJourney(uuid);

  const journeyHasBeenViewed = useRef(false);
  const customFieldValuesRef = useRef<AliasCustomFieldValueType[]>([]);

  const nodeViewEventsRef = useRef<any>({});

  const videoUpNextTimerRef = useRef({
    stopTimer: functionNoop,
    startTimer: functionNoop,
  });
  const videoTimeMapping = useEmbedVideoTimeMap();
  // for node
  const nodeTimer = useTimer();
  const viewTimer = useTimer(2000);

  const { applyBrandColors } = useApplyBrandColors();

  const previousStepPath = usePrevious(stepPath);

  useEffect(() => {
    const brandingBaseColor = journey?.branding?.secondary_color;
    const { brandPrimaryColor, brandSecondaryColor, brandTextColor } = getBrandingColors(
      brandingBaseColor,
      journey?.branding?.force_generated_colors || false
    );
    applyBrandColors(
      brandPrimaryColor,
      brandSecondaryColor,
      brandTextColor,
      journey?.branding?.heading_typeface || 'sans_serif'
    );
  }, [journey, applyBrandColors]);

  useEffect(() => {
    if (journey && currentNode) {
      const isChatPanelEnabled = !!commentIdToScroll;
      if (isChatPanelEnabled) {
        setQueryParams({
          suggestedCommenterUserUUID,
          commentId: commentIdToScroll,
          stepUUID: currentNode.uuid,
          chatPanel: true,
        });
      }
      if (aliasSlug) {
        setJourneySlug(aliasSlug);
      }
    }
  }, [commentIdToScroll, journey, currentUser, currentNode]);

  useEffect(() => {
    if (isChannelChatReady) {
      if (showNextScreen) {
        videoUpNextTimerRef.current.stopTimer();
        isNextScreenCancelledByChatRef.current = true;
      }
    } else if (isNextScreenCancelledByChatRef.current && !isChannelChatReady) {
      videoUpNextTimerRef.current.startTimer();
      isNextScreenCancelledByChatRef.current = false;
    }
  }, [showNextScreen, isChannelChatReady]);

  const isGalleryModeEnabled = useMemo(() => {
    return journey && journey.is_gallery_mode_enabled && galleryMode;
  }, [galleryMode, journey]);

  const journeyTrackingUUID = useMemo(() => trackingUUID || getJourneyTracker(), [trackingUUID]);
  const journeySessionUUID = useMemo(getJourneySession, []);
  const sessionReplayUUID = useMemo(getSessionReplayId, []);

  useEventRecorder(sessionReplayUUID, journey, uuid);

  const queryParams = queryString.parse(window.location.search);

  const {
    isJourneyInactive,
    isJourneyPasswordProtected,
    isJourneyEmailGated,
    isEmailVerificationRequired,
    isNdaSignatureRequired,
    isEmailGateUnlocked,
    checkIfNdaSignatureRequired,
    onNdaSigned,
    customFields,
    unlockEmailGate,
    isJourneyWhitelistEmailGated,
  } = useEmbedJourneyFlags(journey, uuid, userEmail, passwordIsVerified, isGalleryModeEnabled, null);

  useEffect(() => {
    if (journey && userEmail && !isEmailVerificationRequired) {
      checkIfNdaSignatureRequired(userEmail);
    }
  }, [journey, userEmail, isEmailVerificationRequired]);

  useEffect(() => {
    if (journey && !analyticsTriggered && !previewMode) {
      LogAnalytics({ journey: journey, journeyPageType: 'presentation' });
      setAnalyticsTriggered(true);
    }
  }, [journey]);

  const [isGateAlreadyViewed, setIsGateAlreadyViewed] = useState<boolean>(
    getAliasGateViewedForJourney(uuid, journeySessionUUID)
  );

  const isNodeEmailGated = useCallback(
    (node: any) => {
      // if gallery mode is enabled, turn off email gating
      if (galleryMode) return false;

      return !userEmail && !isJourneyEmailGated && node.is_email_required;
    },
    [isJourneyEmailGated, userEmail, galleryMode]
  );

  const isAutoplayEnabled = useCallback(
    (node: any) =>
      node.content_type === 'video' && !isNodeEmailGated(node) && !isJourneyEmailGated && !isNdaSignatureRequired,
    [isJourneyEmailGated, isNodeEmailGated, isNdaSignatureRequired]
  );

  const isJourneyTrackingActive = useMemo(() => {
    // if gallery mode is enabled, turn off tracking.
    if (galleryMode) return false;

    // if preview mode is enabled, turn off tracking
    if (previewMode) return false;

    // if there is no joureny, then turn off tracking
    if (!journey) return false;

    return !isJourneyInactive && !isJourneyPasswordProtected && !isJourneyEmailGated;
  }, [isJourneyEmailGated, isJourneyInactive, isJourneyPasswordProtected, galleryMode, previewMode, journey]);

  const isStepTrackingActive = useCallback(
    (node: any) => {
      return isJourneyTrackingActive && node && !node.is_placeholder && !isJourneyUpsellNode(node);
    },
    [isJourneyTrackingActive]
  );

  const setNodeAsCompleted = useCallback(
    (uuid: string) => {
      allNodes[uuid].node.completed = true;
      setCurrentNode((node: any) => {
        if (node?.uuid === uuid) {
          return allNodes[uuid].node;
        }
        return node;
      });
    },
    [allNodes]
  );

  const trackingUUIDs = useMemo(
    () => ({
      sessionUUID: journeySessionUUID,
      trackingUUID: journeyTrackingUUID,
      sessionReplayUUID: sessionReplayUUID,
      email: userEmail,
    }),
    [journeySessionUUID, journeyTrackingUUID, userEmail]
  );

  const userEventsFactory = embedUserEventsFactory({
    aliasUUID: uuid,
    ...trackingUUIDs,
    email: userEmail,
  });

  const {
    startTrackingPdf,
    changePdfPage,
    stopTrackingPdf,
    pauseTrackingPdf,
    resumeTrackingPdf,
    collectTotalDurationForAllPages,
    reportAllPendingEvents,
  } = usePdfTimeMetricsReporter(userEventsFactory);

  const initNodeViewIfNeeded = useCallback(
    (node: any) => {
      if (!nodeViewEventsRef.current[node.id]) {
        const event = userEventsFactory.createUserViewEvent(node.id);
        nodeViewEventsRef.current[node.id] = { event, duration: 0 };
        if (node.content_type === 'pdf') {
          nodeViewEventsRef.current[node.id].pageViews = {};
        }
      }
    },
    [userEventsFactory]
  );

  const getVideoNodeDuration = useCallback(
    (node: any): Nullable<number> => (node.content_type === 'video' ? videoTimeMapping.get(node.uuid) : null),
    [videoTimeMapping]
  );

  const trackDocumentPageView = useCallback(
    (node: any) => {
      if (!isStepTrackingActive(node) || node.content_type !== 'pdf') {
        return;
      }
      reportAllPendingEvents();
    },
    [isStepTrackingActive, reportAllPendingEvents]
  );

  const trackNodeView = useCallback(
    (node: any) => {
      if (!isStepTrackingActive(node) || !node) {
        return;
      }
      initNodeViewIfNeeded(node);
      const nodeView = nodeViewEventsRef.current[node.id];

      const videoDuration = getVideoNodeDuration(node);
      if (videoDuration) {
        nodeView.duration = Math.ceil(videoDuration);
      } else if (node.content_type === 'pdf') {
        const allPagesTotalDuration = collectTotalDurationForAllPages(node.id);
        nodeView.duration = allPagesTotalDuration || nodeTimer.timerRef.current;
      } else {
        nodeView.duration = nodeTimer.timerRef.current;
      }
      nodeView.event.fire(nodeView.duration);
      trackDocumentPageView(node);
    },
    [
      getVideoNodeDuration,
      initNodeViewIfNeeded,
      isStepTrackingActive,
      nodeTimer.timerRef,
      nodeViewEventsRef,
      trackDocumentPageView,
      collectTotalDurationForAllPages,
    ]
  );

  const trackJourneyView = useCallback(() => {
    if (!isJourneyTrackingActive || journeyHasBeenViewed.current) {
      return;
    }

    const journeyViewEvent = userEventsFactory.createJourneyViewEvent();
    journeyViewEvent.fire(queryParams);
    journeyHasBeenViewed.current = true;
  }, [isJourneyTrackingActive, userEventsFactory, queryParams]);

  const navigateToNode = useCallback(
    (node: any) => {
      if (!currentNode || (currentNode && node && currentNode.id !== node.id)) {
        const parts = history.location.pathname.split('/');
        let newPath = '';

        // this means there is an existing start id, so simply replace it
        if (parts.length > 3) {
          newPath = [...parts.slice(0, parts.length - 1), node.friendly_path].join('/');
        } else {
          newPath = [...parts, node.friendly_path].join('/');
        }

        if (updateStepPathUrl) {
          history.push({
            pathname: newPath,
          });
        } else {
          onStepPathUpdate && onStepPathUpdate(newPath);
        }
      }
    },
    [currentNode, history, onStepPathUpdate, updateStepPathUrl]
  );

  const loadNode = useCallback(
    (node: any) => {
      if (!node) return;

      if (!['video', 'pdf'].includes(node.content_type) || isEmbeddableVideo(node)) {
        delay(() => {
          setNodeAsCompleted(node.uuid);
        }, 600);
      }

      // track before you change to next node
      if (currentNode && node && currentNode.id !== node.id) {
        trackNodeView(currentNode);
        if (currentNode.content_type === 'pdf') {
          stopTrackingPdf();
        }
      }

      /**
       * reset the timer when node is changed
       * if it's visited before, continue from the existing duration
       */
      nodeTimer.timerRef.current = nodeViewEventsRef.current[node.id]?.duration || 0;

      // Track an initial + instant view.
      trackNodeView(node);

      if (showNextScreen) {
        setShowNextScreen(false);
      }
      viewTimer.startTimer(() => {
        trackNodeView(node);
      });
      setCurrentNode(node);
    },
    [
      currentNode,
      nodeTimer.timerRef,
      nodeViewEventsRef,
      setNodeAsCompleted,
      showNextScreen,
      trackNodeView,
      viewTimer,
      stopTrackingPdf,
    ]
  );

  const onNavigationItemClicked = (node: any) => {
    const { node: target } = allNodes[node.uuid];
    navigateToNode(target);
  };

  const onListenMessage = useCallback(
    (e: MessageEvent) => {
      listenIframeEvents(e, userEventsFactory, currentNode);
    },
    [currentNode, userEventsFactory]
  );

  useEffect(() => {
    window.addEventListener('message', onListenMessage);
    return () => {
      window.removeEventListener('message', onListenMessage);
    };
  }, [onListenMessage]);

  const onVisibilityChange = (isHidden: boolean) => {
    if (!currentNode) return;
    if (isHidden) {
      nodeTimer.pauseTimer();
      if (currentNode?.content_type === 'pdf') {
        pauseTrackingPdf();
      }
      viewTimer.pauseTimer();
    } else {
      nodeTimer.startTimer();
      if (currentNode?.content_type === 'pdf') {
        resumeTrackingPdf();
      }
      viewTimer.startTimer(() => {
        trackNodeView(currentNode);
      });
    }
  };

  useDocumentVisibilityChange(onVisibilityChange);

  useEffect(() => {
    if (isJourneyTrackingActive) {
      trackJourneyView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJourneyTrackingActive]);

  useEffect(() => {
    if (isMobileLayout && !startNodeId && !stepPath) {
      return;
    }

    if (journey && !currentNode) {
      if (stepPath) {
        navigateToNode(getNodeByStepPath(journey, stepPath));
      } else if (startNodeId) {
        navigateToNode(getInitialNode(journey, startNodeId));
      } else {
        navigateToNode(getInitialNode(journey));
      }
      nodeTimer.startTimer();
    }
  }, [journey, currentNode, navigateToNode, startNodeId, stepPath, isMobileLayout]);

  useEffect(() => {
    if (journey) {
      if (stepPath && (stepPath !== previousStepPath || !currentNode)) {
        // only load the node on step change or initial load when there is no current node
        loadNode(getNodeByStepPath(journey, stepPath));
      }
    }
  }, [journey, currentNode, loadNode, stepPath, previousStepPath]);

  const getNextStep = (currentStep: any): Nullable<any> => {
    const { steps } = journey;
    let nextStep: Nullable<any> = null;

    const currentStepIndex = steps.findIndex((step: any) => step.id === currentStep.id);
    if (currentStepIndex > -1) {
      const restSteps = steps.slice(currentStepIndex + 1);
      nextStep = restSteps.find((step: any) => step.nodes && step.nodes.length > 0);
    }

    return nextStep;
  };

  const getPreviousStep = (currentStep: any): Nullable<any> => {
    const { steps } = journey;
    let previousStep: Nullable<any> = null;

    const currentStepIndex = steps.findIndex((step: any) => step.id === currentStep.id);

    if (currentStepIndex > -1) {
      const restSteps = steps.slice(0, currentStepIndex);
      previousStep = restSteps.reverse().find((step: any) => step.nodes && step.nodes.length > 0);
    }

    return previousStep;
  };

  const getStepByNode = (node: any): any => journey.steps.find((step: any) => step.id === allNodes[node.uuid].stepId)!;

  const getNextNodeInStep = (step: any) => {
    let nextNode: Nullable<any> = null;
    step.nodes.forEach((stepNode: any, index: number, stepNodes: any[]) => {
      if (stepNode.id === currentNode.id && index < stepNodes.length - 1) {
        nextNode = stepNodes[index + 1];
      }
    });

    return nextNode;
  };

  const getPreviousNodeInStep = (step: any) => {
    let previousNode: Nullable<any> = null;
    step.nodes.forEach((stepNode: any, index: number, stepNodes: any[]) => {
      if (stepNode.uuid === currentNode.uuid && index > 0) {
        previousNode = stepNodes[index - 1];
      }
    });

    return previousNode;
  };

  const getNextNode = (): Nullable<any> => {
    const currentStep = getStepByNode(currentNode);
    const nextNodeInStep = getNextNodeInStep(currentStep);

    if (nextNodeInStep) {
      return nextNodeInStep;
    }

    const nextStep = getNextStep(currentStep);

    if (nextStep && nextStep.nodes.length > 0) {
      return nextStep.nodes[0];
    }

    return null;
  };

  const getPreviousNode = (): Nullable<any> => {
    const currentStep = getStepByNode(currentNode);
    const previousNodeInStep = getPreviousNodeInStep(currentStep);

    if (previousNodeInStep) {
      return previousNodeInStep;
    }

    const previousStep = getPreviousStep(currentStep);

    if (previousStep && previousStep.nodes.length > 0) {
      return previousStep.nodes[previousStep.nodes.length - 1];
    }

    return null;
  };

  const updateNodeCurrentTime = (node: any, currentTime?: number) => {
    if (currentTime) {
      const existingNodeTime = videoTimeMapping.get(node.uuid);
      videoTimeMapping.set(node.uuid, existingNodeTime ? Math.max(existingNodeTime, currentTime) : currentTime);
    }
  };

  const onNextClicked = () => {
    if (!currentNode) {
      navigateToNode(getInitialNode(journey));
    } else {
      const nextNode = getNextNode();
      if (nextNode) {
        navigateToNode(nextNode);
      }
    }
  };

  const onPreviousClicked = () => {
    const previousNode = getPreviousNode();
    if (previousNode) {
      navigateToNode(previousNode);
    } else if (isMobileLayout) {
      // on mobile, if the user clicks on previous button on first node,
      // then take them to mobile contents page and set current step to null.
      setCurrentNode(null);
    }
  };

  const onVideoPlayed = () => {
    // send video_play event only once per node
    if (!objectPath.get(allNodes[currentNode.uuid], 'node.videoPlayed', false)) {
      allNodes[currentNode.uuid].node.videoPlayed = true;
      const videoPlayEvent = userEventsFactory.createVideoPlayEvent(currentNode.id);
      videoPlayEvent.fire();
    }

    setCurrentNode({ ...allNodes[currentNode.uuid].node });
  };

  const onVideoPaused = () => {
    // track video pause event if necessary.
  };

  const onVideoEnded = (node: any, currentTime?: number) => {
    const videoEndEvent = userEventsFactory.createVideoEndEvent(currentNode.id);
    allNodes[currentNode.uuid].node.progressPercentage = 100;
    setNodeAsCompleted(currentNode.uuid);
    setShowNextScreen(true);
    updateNodeCurrentTime(node, currentTime);
    trackNodeView(node);
    videoEndEvent.fire();
  };

  const onVideoProgressed = (node: any, currentTime: number, duration: number) => {
    if (duration > 0) {
      const percentage = (currentTime / duration) * 100;
      allNodes[node.uuid].node.progressPercentage = percentage;
      if (percentage > NODE_COMPLETION_THRESHOLD) {
        setNodeAsCompleted(node.uuid);
      }
      setCurrentNode({ ...allNodes[node.uuid].node });
    }
  };

  const onVideoTimeUpdated = (node: any, currentTime?: number) => {
    updateNodeCurrentTime(node, currentTime);
  };

  const updateNodeProgress = (node: any, percentage: number) => {
    allNodes[node.uuid].node.progressPercentage = percentage;
    if (percentage > NODE_COMPLETION_THRESHOLD) {
      setNodeAsCompleted(currentNode.uuid);
    }
    setCurrentNode({ ...allNodes[node.uuid].node });
  };

  const onPDFLoaded = useCallback(() => {
    if (currentNode.content_type !== 'pdf') {
      return;
    }
    startTrackingPdf(currentNode.id);
    // Doing this after 50ms to force at least 1 event per PDF load.
    delay(() => {
      trackDocumentPageView(currentNode);
    }, 50);
  }, [currentNode, trackDocumentPageView, startTrackingPdf]);

  const onPDFPageChanged = (pageIndex: number, pageCount: number) => {
    if (pageCount > 0) {
      changePdfPage(pageIndex);
      const percentage = ((pageIndex + 1) / pageCount) * 100;
      updateNodeProgress(currentNode, percentage);
    }
  };

  const onExternalLinkVisited = () => {
    const linkVisitEvent = userEventsFactory.createExternalLinkVisitEvent(currentNode.id);
    return linkVisitEvent.fire({});
  };

  const onSignNda = (email: string, signature: string, customFields?: { fullName: string; companyName: string }) => {
    apiSignNda(journey.alias.uuid, email, signature, customFields?.fullName, customFields?.companyName).then(() => {
      onNdaSigned();
      // add success notification
      setSuccessNotification('Signed and email sent to you.');
      return onJourneyUnlocked(userEmail || '');
    });
  };

  const onJourneyUnlockedPendingNda = (email?: string) => {
    return checkIfNdaSignatureRequired(email || '')
      .then((isNdaRequired: boolean) => {
        if (!isNdaRequired) {
          onJourneyUnlocked(email || userEmail || '', journey.alias.uuid);
        }
      })
      .catch(unlockEmailGate);
  };

  const onJourneyUnlocked = async (email?: string, alias_uuid: string = uuid) => {
    setAliasGateViewedForJourney(alias_uuid, journeySessionUUID);
    setIsGateAlreadyViewed(true);
    setPasswordIsVerified(true);
    unlockEmailGate();

    let response = null;

    await checkIfNdaSignatureRequired(email || '');

    if (email && journey?.creator?.email !== email) {
      const emailCaptureEvent = userEventsFactory.createEmailCaptureEvent(email);
      response = await emailCaptureEvent.fire(customFieldValuesRef.current);
      storeAccessedEmailForJourney(alias_uuid, email);
      trackJourneyView();
      setUserEmail(email);
      trackSegmentEvent('Email Gate Passed', {
        email: email,
        journey_alias_uuid: alias_uuid,
      });
    } else {
      storeAccessedEmailForJourney(alias_uuid, 'anonymous');
      trackJourneyView();
    }

    return response;
  };

  const onCustomFieldValuesSubmitted = (customFieldValues: AliasCustomFieldValueType[]) => {
    // set thid to local ref value
    customFieldValuesRef.current = customFieldValues.map((customField) => ({
      ...customField,
      type: customField.field === 'phone_number' ? 'number' : 'string',
      value: customField.field === 'phone_number' ? parseInt(customField.value.toString(), 10) : customField.value,
    }));
  };

  useEffect(() => {
    // if there is a token in the url, try to unlock the journey with it.
    if (token) {
      apiUnlockJourneyByToken(token)
        .then((res) => {
          const { email, alias_uuid } = res;
          return onJourneyUnlocked(email, alias_uuid);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const renderEmailGating = (node: any) => {
    if (!isNodeEmailGated(node)) {
      return null;
    }

    const onEmailCaptured = async ({ email }: { email: string }) => {
      const emailCaptureEvent = userEventsFactory.createEmailCaptureEvent(email);
      return emailCaptureEvent.fire().then((response) => {
        storeAccessedEmailForJourney(uuid, email);
        setUserEmail(email);
        return response;
      });
    };

    if (journey.creator.email === userEmail) {
      return null;
    }

    return (
      <StepEmailGating
        journey={journey}
        email={userEmail}
        onSubmit={onEmailCaptured}
        onBypassClick={(email) => {
          onJourneyUnlocked(email);
          setUserEmail(email);
        }}
      />
    );
  };

  const emailRequired = isJourneyEmailGated || isJourneyWhitelistEmailGated;
  const introVideoAsset = objectPath.get(journey, 'alias.intro_video_asset', null);

  const shouldRenderJourneyGate = useMemo(() => {
    const showIntroVideo = Boolean(introVideoAsset && !isGateAlreadyViewed);

    return (
      (emailRequired || isJourneyPasswordProtected || isNdaSignatureRequired || showIntroVideo) && !isEmailGateUnlocked
    );
  }, [
    emailRequired,
    introVideoAsset,
    isEmailGateUnlocked,
    isGateAlreadyViewed,
    isJourneyPasswordProtected,
    isNdaSignatureRequired,
  ]);

  const renderContent = () => {
    const viewContentCallbacks: Record<string, Function> = {
      onVideoPlay: onVideoPlayed,
      onVideoPause: onVideoPaused,
      onVideoEnded: onVideoEnded,
      onVideoProgres: onVideoProgressed,
      onVideoTimeUpdate: onVideoTimeUpdated,
      onExternalLinkNavigate: onExternalLinkVisited,
    };
    if (currentNode.content_type === 'pdf') {
      viewContentCallbacks.onDocumentLoad = onPDFLoaded;
      viewContentCallbacks.onPageChange = onPDFPageChanged;
    }
    return (
      <JourneyViewNode
        node={currentNode}
        journey={journey}
        galleryMode={isGalleryModeEnabled}
        autoplay={isAutoplayEnabled(currentNode)}
        canEdit={journey.can_edit}
        brandingColor={getBrandingColor(journey).hex}
        showDocumentDownload={journey.feature_flags.show_document_download_button}
        {...viewContentCallbacks}
      />
    );
  };

  const renderVideo = (_: any, showPlayer: boolean, fluid = false) => {
    if (isPlaceholderNode(currentNode)) {
      return null;
    }

    return (
      <VideoPlayer
        showPlayer={showPlayer}
        fluid={fluid}
        autoplay={isAutoplayEnabled(currentNode)}
        currentNode={currentNode}
        journey={journey}
        onVideoPlay={onVideoPlayed}
        onVideoPause={onVideoPaused}
        onVideoEnded={onVideoEnded}
        onVideoProgress={onVideoProgressed}
        onVideoTimeUpdate={onVideoTimeUpdated}
      />
    );
  };

  const renderVideoNextScreen = () => {
    const nextNode = getNextNode();

    if (!showNextScreen || !nextNode) {
      return null;
    }

    const navigateToNextNode = () => navigateToNode(nextNode);

    return (
      <VideoUpNext
        journey={journey}
        timerRef={videoUpNextTimerRef}
        nextTitle={nextNode.name}
        onPlayClick={navigateToNextNode}
        onCancelClick={() => {
          setShowNextScreen(false);
        }}
        onCountdownEnd={navigateToNextNode}
      />
    );
  };

  if (!journey) {
    return null;
  }

  if (isJourneyInactive) {
    return <JourneyInactive journey={journey} />;
  }

  return (
    <>
      <DynamicMeta disableIndexing={disableIndexing} />
      <FeatureFlagsContextProvider value={journey?.feature_flags}>
        {shouldRenderJourneyGate ? (
          <EmbedPlayerEmailGating
            userEmail={userEmail}
            journey={journey}
            uuid={uuid}
            trackingUUID={journeyTrackingUUID}
            sessionUUID={journeySessionUUID}
            emailRequired={emailRequired}
            passwordRequired={isJourneyPasswordProtected}
            emailVerificationRequired={isEmailVerificationRequired}
            ndaSignatureRequired={isNdaSignatureRequired}
            customFields={customFields}
            introVideoAsset={introVideoAsset}
            onJourneyUnlocked={onJourneyUnlockedPendingNda}
            onNdaSigned={onSignNda}
            onEmailSubmitted={setUserEmail}
            onCustomFieldValuesSubmitted={onCustomFieldValuesSubmitted}
          />
        ) : (
          <PlayerBedrockComponent
            currentNode={currentNode}
            journey={journey}
            allNodes={allNodes}
            isDeepLinked={Boolean(startNodeId || stepPath)}
            onClickNext={onNextClicked}
            onClickPrevious={onPreviousClicked}
            renderContent={renderContent}
            renderVideo={renderVideo}
            emailRequired={emailRequired}
            renderVideoNextScreen={renderVideoNextScreen}
            renderEmailGating={renderEmailGating}
            onNavigationItemClick={onNavigationItemClicked}
            isEmailGateUnlocked={isEmailGateUnlocked}
            galleryMode={isGalleryModeEnabled}
            userEventsFactory={userEventsFactory}
          />
        )}
      </FeatureFlagsContextProvider>
    </>
  );
};
