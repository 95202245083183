import { v4 as uuidv4 } from 'uuid';
import { localStorageGetItem, localStorageSetItem } from './storage';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function getJourneyTracker() {
  let tracker = null;

  const trackerCookie = getCookie('_journey_tracker');
  if (trackerCookie) {
    tracker = trackerCookie;
  } else {
    tracker = localStorageGetItem('journey_tracker');
  }

  if (!tracker) {
    tracker = uuidv4().replace(/-/g, '');
  }

  localStorageSetItem('journey_tracker', tracker);

  return tracker;
}

function getJourneySession() {
  const sessionCookie = getCookie('_journey_session');
  if (sessionCookie) {
    return sessionCookie;
  }

  const newSession = uuidv4().replace(/-/g, '');
  return newSession;
}

function getSessionReplayId() {
  return uuidv4();
}

export { getJourneyTracker, getJourneySession,getSessionReplayId };
