import React, { useRef, useState, DragEventHandler, useEffect } from 'react';
import { Nullable } from 'src/types/nullable.type';
import classNames from 'classnames';
import { animated } from '@react-spring/web';
import LoadingSpinner from 'src/common/components/loading-spinner';
import FileDropzone from 'src/common/FileDropzone';
import { UploadRegularIcon } from 'src/monet/icons';
import { ClipboardContentType } from '../types';

type FileUploadFormProps = {
  onNewFile: (file: File) => void;
  accept?: string;
  containerClassName?: string;
  clipboardData?: Nullable<ClipboardContentType>;
  autoGenerate?: boolean;
  onUploadCLipboardFile?: () => void;
  label?: string;
  multiple?: boolean;
};

export const FileUploadForm = ({
  onNewFile,
  accept,
  label = 'Click to upload',
  autoGenerate,
  clipboardData,
  onUploadCLipboardFile,
  containerClassName = 'h-[88px] px-6 py-4',
  multiple = false,
}: FileUploadFormProps) => {
  const logoUploadRef = useRef<Nullable<HTMLInputElement>>(null);
  const [showFileUploadAnimation, setShowFileUploadAnimation] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onFileUploadClick = () => {
    if (logoUploadRef.current) {
      logoUploadRef.current.click();
    }
  };

  const onFilesUpdate = async (files: Array<File>) => {
    if (files.length > 0) {
      const file = files[0];
      try {
        setUploading(true);
        onNewFile(file);
      } finally {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    if (autoGenerate && clipboardData && clipboardData.file && onUploadCLipboardFile && !clipboardData.dropped) {
      onFilesUpdate([clipboardData.file]);
      onUploadCLipboardFile();
    }
  }, [clipboardData, autoGenerate]);

  const onMouseEnter = () => {
    setShowFileUploadAnimation(true);
  };

  const onMouseLeave = () => {
    setShowFileUploadAnimation(false);
  };

  const onDrag: DragEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('onDrag', event.type);
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setShowFileUploadAnimation(true);
    } else if (event.type === 'dragleave') {
      setShowFileUploadAnimation(false);
    }
  };

  const onDrop: DragEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;

    if (!dataTransfer) {
      return;
    }
    onFilesUpdate(Array.from(dataTransfer.files));
    setShowFileUploadAnimation(false);
  };
  return (
    <div
      className={classNames(
        'group flex justify-center items-center rounded-lg transition cursor-pointer relative',
        {
          'hover:bg-neue-journey-accent-10': !uploading,
          'bg-neue-journey-accent-10': showFileUploadAnimation,
          'bg-neue-journey-fg-10': !showFileUploadAnimation,
        },
        containerClassName
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragEnter={onDrag}
      onDragLeave={onDrag}
      onDragOver={onDrag}
      onDrop={onDrop}
      onClick={() => !uploading && onFileUploadClick()}
    >
      <svg
        className={classNames('absolute overflow-visible transition ', {
          'text-neue-journey-fg-20': !showFileUploadAnimation,
          'text-neue-journey-accent': showFileUploadAnimation,
        })}
        width='100%'
        height='100%'
      >
        <animated.rect
          width='100%'
          height='100%'
          fill='none'
          stroke='currentColor'
          strokeDasharray='3 6'
          // strokeDashoffset={props.strokeDashoffset}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          rx='8'
          ry='8'
          vectorEffect='non-scaling-stroke'
        />
      </svg>
      {uploading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='hidden'>
            <FileDropzone
              accept={accept}
              type='file'
              className='file-dropzone'
              multiple={multiple}
              onFilesUpdate={onFilesUpdate}
              resetOnSuccess={true}
              dropzoneClasses={null}
              inputRef={logoUploadRef}
              disabled={false}
            />
          </div>
          <div className='flex items-center gap-2 transition-colors text-neue-journey-fg-50 group-hover:text-neue-journey-fg'>
            <UploadRegularIcon className='w-4 h-4 shrink-0' />
            <div className='text-neue-journey-small'>{label}</div>
          </div>
        </>
      )}
    </div>
  );
};
